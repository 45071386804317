import React, {useState, useEffect} from 'react';
import {format, differenceInSeconds, differenceInHours, differenceInMinutes, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears} from 'date-fns';
import './App.css';
import satie from './satie-gymnopedie1.mp3'
import skull from './skull-icon.svg'
import dbd from './dbd-digital.png'
function App() {
  const [countdownInSeconds, setCountdownInSeconds] = useState(null);
  const [countdownInYears, setCountdownInYears] = useState(null);
  const [countdownInMonths, setCountdownInMonths] = useState(null)
  const [countdownInWeeks, setCountdownInWeeks] = useState(null);
  const [countdownInDays, setCountdownInDays] = useState(null)
  const [countdownInHours, setCountdownInHours] = useState(null)
  const [countdownInMinutes, setCountdownInMinutes] = useState(null)

  const [deathDay, setDeathDay] = useState(1)
  const [deathMonth, setDeathMonth] = useState(1)
  const [deathYear, setDeathYear] = useState(1970)

  const [dayActive, setDayActive] = useState(false)
  const [monthActive, setMonthActive] = useState(false)
  const [yearActive, setYearActive] = useState(false)

  const [introductionActive, setIntroductionActive] = useState(true);
  const [introductionFade, setIntroductionFade] = useState(false);

  const [gridInfoActive, setGridInfoActive] = useState(false);
  const [gridInfoFade, setGridInfoFade] = useState(false);

  const [christmasActive, setChristmasActive] = useState(false);
  const [christmasFade, setChristmasFade] = useState(false);

  const [moonsActive, setMoonsActive] = useState(false);
  const [moonsFade, setMoonsFade] = useState(false)

  const [weekendsActive, setWeekendsActive] = useState(false)
  const [weekendsFade, setWeekendsFade] = useState(false);

  const [daysActive, setDaysActive] = useState(false);
  const [daysFade, setDaysFade] = useState(false);

  const [finishActive, setFinishActive] = useState(false);
  const [finishFade, setFinishFade] = useState(false);

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      setCountdownInSeconds(prevCount => prevCount - 1);
    }, 1000); 

    const minutesInterval = setInterval(() => {
      setCountdownInMinutes(prevCount => prevCount - 1);
    }, 60000); 

   

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(secondsInterval)
      clearInterval(minutesInterval)
    };
  }, [])

  const handleDeathDate = () => {
    const futureDate = new Date(`${deathYear + 80}-${deathMonth <= 9 ? '0' + deathMonth : deathMonth}-${deathDay <= 9 ? '0' + deathDay : deathDay}`)
    const now = new Date()
    setCountdownInSeconds(differenceInSeconds(futureDate, now))
    setCountdownInMinutes(differenceInMinutes(futureDate,now))
    setCountdownInHours(differenceInHours(futureDate, now))
    setCountdownInDays(differenceInDays(futureDate, now))
    setCountdownInWeeks(differenceInWeeks(futureDate, now))
    setCountdownInMonths(differenceInMonths(futureDate, now))
    setCountdownInYears(differenceInYears(futureDate, now))
    handleIntroductionContinue()
  }

  const handleIntroductionContinue = () => {
    setIntroductionFade(true)
    setTimeout(() => {
      setIntroductionActive(false)
      setGridInfoActive(true)
      setFinishFade(false)
    }, 2000)
  }

  const handleGridContinue = () => {
    setGridInfoFade(true)
    setTimeout(() => {
      setGridInfoActive(false)
      setChristmasActive(true)
    }, 2000)
  }

  const handleChristmasContinue = () => {
    setChristmasFade(true)
    setTimeout(() => {
      setChristmasActive(false)
      setMoonsActive(true)
    }, 2000)
  }

  const handleMoonsContinue = () => {
    setMoonsFade(true)
    setTimeout(() => {
      setMoonsActive(false)
      setWeekendsActive(true)
    }, 2000)
  }

  const handleWeekendsContinue = () => {
    setWeekendsFade(true)
    setTimeout(() => {
      setWeekendsActive(false)
      setDaysActive(true)
    }, 2000)
  }

  const handleDaysContinue = () => {
    setDaysFade(true)
    setTimeout(() => {
      setDaysActive(false)
      setFinishActive(true)
    }, 2000)
  }

  const handleRestart = () => {
    setFinishFade(true)
    setTimeout(() => {
      setFinishActive(false)
      setIntroductionActive(true)
      setIntroductionFade(false);
      setGridInfoFade(false)
      setChristmasFade(false)
      setMoonsFade(false)
      setWeekendsFade(false)
      setDaysFade(false)
    }, 2000)
  }

  return (
    <div className="min-h-[100vh] main bg-white">
      <div className={`dbd-logo very-slow-fade-in flex justify-center ${introductionFade ? 'slow-fade-out' : ''}`}>
        <img src={dbd} />
      </div>
{ introductionActive &&
      <div className={`h-[100vh] flex items-center justify-center slow-fade-in ${introductionFade ? 'slow-fade-out' : ''}`}>
        <div>
          <div className="md:block flex justify-center items-center">
            <img className={'skull mt-24 md:mt-0'} src={skull} />
          </div>
        <div className={'flex justify-center mb-4'}>
            <label className="text-waw-blue text-center text-lg">Please enter your birthday</label>
        </div>
        <div className="rounded-lg bg-waw-blue p-2 py-0">
          <div className="flex gap-2 mt-8 mb-8 py-4 justify-center">
            
            <div className={'relative'}>
                <p onClick={() => setDeathDay(d => d < 31 ? d + 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">△</p>
              <input onChange={(e) => setDeathDay(parseInt(e.target.value) <= 31 ? parseInt(e.target.value) : deathDay)} onMouseLeave={() => setDayActive(false)} onClick={() => setDayActive(true)} className={`${dayActive ? 'opacity-100' : 'opacity-0'} absolute bg-gray-100 w-[100%] h-[64px] md:h-[92px] text-2xl md:text-6xl text-waw-blue text-center`} />
              <div className="text-waw-blue text-center py-4 bg-white border-b-2 border-waw-blue bg-gray-100 text-2xl md:text-6xl  w-[90px] md:w-[180px]" >
                {deathDay <= 9 ? '0' + deathDay : deathDay}
              </div>
              <p onClick={() => setDeathDay(d => d > 1 ? d - 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">▽</p>
            </div>

            <div className={'relative'}>
                <p onClick={() => setDeathMonth(d => d < 12 ? d + 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">△</p>
              <input onChange={(e) => setDeathMonth(parseInt(e.target.value) <= 12 ? parseInt(e.target.value) : deathMonth)} onMouseLeave={() => setMonthActive(false)} onClick={() => setMonthActive(true)} className={`${monthActive ? 'opacity-100' : 'opacity-0'} absolute bg-gray-100 w-[100%] h-[64px] md:h-[92px] text-2xl md:text-6xl text-waw-blue text-center`} />
              <div className="text-waw-blue text-center py-4 bg-white border-b-2 border-waw-blue bg-gray-100 text-2xl md:text-6xl  w-[90px] md:w-[180px]" >
                {deathMonth <= 9 ? '0' + deathMonth : deathMonth}
              </div>
              <p onClick={() => setDeathMonth(d => d > 1 ? d - 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">▽</p>
            </div>

            <div className={'relative'}>
                <p onClick={() => setDeathYear(d => d < 2024 ? d + 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">△</p>
              <input onChange={(e) => setDeathYear(parseInt(e.target.value))} onMouseLeave={() => setYearActive(false)} onClick={() => setYearActive(true)} className={`${yearActive ? 'opacity-100' : 'opacity-0'} absolute bg-gray-100 w-[100%] h-[64px] md:h-[92px] text-2xl md:text-6xl text-waw-blue text-center`} />
              <div className=" text-waw-blue text-center py-4 bg-white border-b-2 border-waw-blue bg-gray-100 text-2xl md:text-6xl  w-[90px] md:w-[180px]" >
                {deathYear <= 9 ? '0' + deathYear : deathYear}
              </div>
              <p onClick={() => setDeathYear(d => d > 1 ? d - 1 : d)} className="select-none cursor-pointer text-white text-4xl text-center">▽</p>
            </div>

           </div>
          </div>
          <div className={'flex justify-center mt-8'}>
           <button onClick={handleDeathDate} className={'py-4 px-16 border border-waw-blue bg-waw-blue text-white rounded-lg text-xl md:text-2xl'}>Continue</button>
          </div>
        </div>
      </div>
}
{ gridInfoActive &&
      <div onClick={handleGridContinue} className={`h-[100vh] flex items-center justify-center slow-fade-in ${gridInfoFade ? 'slow-fade-out' : ''}`}>
        <div className={'max-w-[940px] w-full mx-auto'}>
        <p class="text-waw-blue text-center mb-4 text-xl md:text-2xl mb-4 md:mb-8">How long do you really have left?</p>
          <div className="grid grid-cols-2 md:grid-cols-3 px-2 md:p-0">
              <div className="col-span-1 bg-waw-blue rounded-tl-2xl">
                  <h2 className="text-white text-center pt-8 md:pt-16 text-lg font-extrabold">Your years</h2>
                  <p className="text-white text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInYears}</p>
              </div>
              <div className="col-span-1 border rounded-tr-2xl md:rounded-none">
                  <h2 className="text-waw-blue text-center pt-8 md:pt-16 text-lg font-extrabold">Your months</h2>
                  <p className="text-waw-blue text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInMonths.toLocaleString()}</p>
              </div>
              <div className="col-span-1 border bg-transparent md:bg-waw-blue md:rounded-tr-xl">
                  <h2 className="text-waw-blue md:text-white text-center pt-8 md:pt-16 text-lg font-extrabold">Your weeks</h2>
                  <p className="text-waw-blue md:text-white text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInWeeks.toLocaleString()}</p>
              </div>
              <div className="col-span-1 border md:rounded-bl-xl md:bg-transparent bg-waw-blue">
                  <h2 className="text-white md:text-waw-blue text-center pt-8 md:pt-16 text-lg font-extrabold">Your days</h2>
                  <p className="text-white md:text-waw-blue text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInDays.toLocaleString()}</p>
              </div>
              <div className="col-span-1 border bg-waw-blue md:rounded-none rounded-bl-xl">
                  <h2 className="text-white text-center pt-8 md:pt-16 text-lg font-extrabold">Your hours</h2>
                  <p className="text-white text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInHours.toLocaleString()}</p>
              </div>
              <div className="col-span-1 border rounded-br-xl">
                  <h2 className="text-waw-blue text-center pt-8 md:pt-16 text-lg font-extrabold">Your minutes</h2>
                  <p className="text-waw-blue text-center pb-8 md:pb-16 text-4xl md:text-6xl">{countdownInMinutes.toLocaleString()}</p>
              </div>
          </div>
          <div>
            <p className={'text-waw-blue mt-6 md:mt-8 font-extrabold text-center'}>Your Seconds</p>
            <h2 className={'text-waw-blue text-center text-4xl md:text-6xl'}>{countdownInSeconds.toLocaleString()}</h2>
          </div>
          <div className="flex justify-center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-waw-blue mt-6 md:mt-14 w-12 h-12">
         <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>
      </div>
      <p className={'text-waw-blue text-center'}>Click to continue</p>
     {/*     <div className={'flex justify-center mt-8'}>
            <button onClick={handleGridContinue} className={'py-4 px-16 border bg-waw-blue border-waw-blue rounded-lg text-white text-2xl'}>Continue</button>
</div> */}       
          </div>
      </div>
}

{ christmasActive &&
  <div onClick={handleChristmasContinue} className={`h-[100vh] flex items-center justify-center slow-fade-in ${christmasFade ? 'slow-fade-out' : ''}`}>
      <div>
      <p className="mt-8 md:mt-16 text-xl text-waw-blue text-center">This is how many Christmas holidays you have left</p>
      <div class="flex-wrap flex gap-4 p-8 justify-center">
        {
          [...Array(countdownInYears).keys()].map(index => index + 1)
          .map(() => (
            <p className="text-waw-blue text-4xl filter-blue"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M210.6 5.9L62 169.4c-3.9 4.2-6 9.8-6 15.5C56 197.7 66.3 208 79.1 208H104L30.6 281.4c-4.2 4.2-6.6 10-6.6 16C24 309.9 34.1 320 46.6 320H80L5.4 409.5C1.9 413.7 0 419 0 424.5c0 13 10.5 23.5 23.5 23.5H192v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448H424.5c13 0 23.5-10.5 23.5-23.5c0-5.5-1.9-10.8-5.4-15L368 320h33.4c12.5 0 22.6-10.1 22.6-22.6c0-6-2.4-11.8-6.6-16L344 208h24.9c12.7 0 23.1-10.3 23.1-23.1c0-5.7-2.1-11.3-6-15.5L237.4 5.9C234 2.1 229.1 0 224 0s-10 2.1-13.4 5.9z"/></svg></p>
            ))
          }
      </div>
      <p className="mt-16 text-waw-blue text-center mt-2"><i>"With each passing year, we gain a deeper understanding of the fragility and beauty of life, and the urgency to live authentically and purposefully."</i></p>
      <div className="flex justify-center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-waw-blue mt-8 md:mt-14 w-12 h-12">
         <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>
      </div>
      <p className={'text-waw-blue text-center'}>Click to continue</p>
      </div>
  </div>
  }

  { moonsActive &&
  <div onClick={handleMoonsContinue} className={`h-[100vh] flex items-center justify-center slow-fade-in ${moonsFade ? 'slow-fade-out' : ''}`}>
    <div>
        <p className="mt-8 md:mt-16 text-xl text-waw-blue text-center">This is how many full moons you have left to witness</p>
        <div class="flex-wrap flex gap-0 md:gap-4 p-8 justify-center">
          {
            [...Array(countdownInMonths).keys()].map(index => index + 1)
            .map(() => (
              <p className="text-waw-blue text-4xl"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 md:w-8 md:h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
            </svg>
            </p>
            ))
          }
        </div>
      <p className="text-waw-blue text-center mt-2"><i>"Just as the moon waxes and wanes, so do the months of our lives, carrying us through cycles of beginnings, endings, and everything in between."</i></p>
      <div className="flex justify-center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-waw-blue mt-6 md:mt-14 w-12 h-12">
         <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>
      </div>
      <p className={'text-waw-blue text-center'}>Click to continue</p>
    </div>
  </div>
  }

{ weekendsActive &&
  <div onClick={handleWeekendsContinue} className={`h-[100vh] flex items-center justify-center slow-fade-in ${weekendsFade ? 'slow-fade-out' : ''}`}>
      <div>
      <p className="mt-8 md:mt-16 text-xl text-waw-blue text-center">This is how many weekends you have left</p>
      <div class="flex-wrap flex gap-0 md:gap-1 p-8">
        {
          [...Array(countdownInWeeks).keys()].map(index => index + 1)
          .map(() => (
            <p className="text-waw-blue text-md md:text-4xl leading-[8px] md:leading-[16px]">&#x2022;</p>
          ))
        }
      </div>
      <p className="text-waw-blue text-center mt-2"><i>"The weekend is a reminder that life is not just about existing but about truly living."</i></p>
      <div className="flex justify-center w-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-waw-blue mt-14 w-12 h-12">
         <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>
      </div>
      <p className={'text-waw-blue text-center'}>Click to continue</p>
    </div>
    </div>
}
{ daysActive &&
    <div onClick={handleDaysContinue} className={`h-[100vh] flex items-center justify-center slow-fade-in ${daysFade ? 'slow-fade-out' : ''}`}>
      <div>
      <p className="mt-8 md:mt-16 text-xl text-waw-blue text-center translate-y-[12.5vh] md:translate-y-[0]">This is how many days you have left</p>
      <div class="flex-wrap flex p-2 md:p-8 scale-50 md:scale-100">
        {
          [...Array(countdownInDays).keys()].map(index => index + 1)
          .map(() => (
            <p className="text-waw-blue text-sm md:text-md leading-[5px] md:leading-[11px]">&#x2022;</p>
            ))
          }
      </div>
      <p className="text-waw-blue text-center mt-2 -translate-y-[12.5vh] md:translate-y-[0]"><i>"What are days for? Days are where we live. They come, they wake us time and time over."</i></p>
      <div className="flex justify-center w-full -translate-y-[12.5vh] md:translate-y-[0]">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-waw-blue mt-14 w-12 h-12">
         <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>
      </div>
      <p className={'text-waw-blue text-center -translate-y-[12.5vh] md:translate-y-[0]'}>Click to continue</p>
    </div>
    </div>
 } 
 { finishActive &&
    <div className={`h-[100vh] flex items-center justify-center slow-fade-in ${finishFade ? 'slow-fade-out' : ''}`}>
      <div>
      <p class="text-waw-blue text-center text-xl mb-8">How long do you really have?</p>
      <div>
      <button onClick={handleRestart} className={'hover:bg-waw-blue hover:text-white very-slow-fade-in border border-waw-blue rounded-xl text-waw-blue py-4 px-8'}>Do you wish you could start again?</button>
      </div>
    </div>
    </div>
 }
 <svg  style={{display: "none"}}><filter  id="wavy"><feTurbulence _ngcontent-serverApp-c2="" baseFrequency="0.02" numOctaves="5" seed="2" x="0" y="0"><animate _ngcontent-serverApp-c2="" attributeName="baseFrequency" dur="120s" repeatCount="indefinite" values="0.02;0.05;0.02"></animate></feTurbulence><feDisplacementMap _ngcontent-serverApp-c2="" in="SourceGraphic" scale="40"></feDisplacementMap></filter></svg>
 </div>
  );
}

export default App;
